import React, { useState } from 'react';
import { Plus, Barcode, Camera } from 'lucide-react'; // Icons
import { useZxing } from 'react-zxing'; // react-zxing hook for QR scanning

export const BarcodeScanner = ({ onScan }) => {
  const { ref } = useZxing({
    onDecodeResult(result) {
      if (result) {
        onScan(result.getText());
      }
    },
  });

  return (
    <>
      <video ref={ref} style={{ width: '100%' }} />
    </>
  );
};
const Dashboard = () => {
  const [deviceCode, setDeviceCode] = useState('');
  const [isValidDeviceCode, setIsValidDeviceCode] = useState(false);
  const [hasInteracted, setHasInteracted] = useState(false);
  const [showScanner, setShowScanner] = useState(false); // Toggle QR code scanner visibility

  // Validate the device code (assumed to be 10 hex digits)
  const validateDeviceCode = (code) => /^[0-9A-Fa-f]{10}$/.test(code);

  const handleDeviceCodeChange = (e) => {
    const code = e.target.value;
    setDeviceCode(code);
    setIsValidDeviceCode(validateDeviceCode(code));
    setHasInteracted(true);
  };

  // Handle the result from QR code scan
  const handleQrResult = (result) => {
    if (result) {
      const expectedPrefix = 'https://app.volton.ee/?c=';
    
      // Check if the result starts with the expected URL
      if (result.startsWith(expectedPrefix)) {
        const code = result.slice(expectedPrefix.length); // Extract the part after "?c="
        
        // Set the extracted code as the device code
        setDeviceCode(code);
        setIsValidDeviceCode(validateDeviceCode(code));
        setShowScanner(false); // Close the scanner after a successful scan
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isValidDeviceCode) {
      window.location.href = `https://app.volton.ee/?c=${deviceCode}`;
    }
  };

  return (
    <div className="bg-gray-900 min-h-screen flex flex-col items-center justify-center p-4 mb-12">
      <svg className="w-24 h-24 mb-4" viewBox="0 0 333 333" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M119.684 47.5H48L106.5 284.5L177 285L119.684 47.5Z" fill="white" stroke="white" />
        <path d="M172.5 165L212 47.5L298 47L245 128H293L177.5 285L218 165H172.5Z" fill="white" />
      </svg>

      <h1 className="text-2xl font-semibold mb-6 text-white flex items-center">
        Tere tulemast!
      </h1>

      <div className="bg-gray-800 p-8 pt-6 pb-6 rounded-2xl shadow-lg border border-gray-700 max-w-md w-full flex mb-6 flex-col">
        <h2 className="text-lg font-semibold mb-3 text-white flex items-center">
          <Plus size={24} className="mr-2 text-brand" /> Seadme lisamine
        </h2>
        <p className="text-gray-300 opacity-90 text-sm mb-6  items-center">
        Sisestage <span className="font-bold">seadistuskood</span>, mille leiate pakendis olevalt infolehelt, või skanneerige kaameraga <span className="font-bold">QR-kood</span>.
        </p>

        <form onSubmit={handleSubmit} className="space-y-4 mb-4">
          <div className="relative">
            <Barcode size={20} className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500" />
            <input
              type="text"
              value={deviceCode}
              onChange={handleDeviceCodeChange}
              placeholder="Seadistuskood"
              className={`pl-10 pr-12 py-2 w-full rounded-md bg-gray-700 text-white border ${
                hasInteracted && !isValidDeviceCode
                  ? 'border-red-500 focus:border-red-500'
                  : 'border-gray-600 focus:border-brand'
              } focus:ring focus:ring-opacity-50 focus:ring-brand`}
              required
            />
            <Camera
              size={24}
              className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500 cursor-pointer"
              onClick={() => setShowScanner(!showScanner)}
            />
          </div>

          {showScanner && (
            <div className="mt-4">
              {/* QR Code Scanner using react-zxing */}
              <BarcodeScanner onScan={handleQrResult} />
            </div>
          )}

          {hasInteracted && !isValidDeviceCode && (
            <p className="text-red-500 text-sm mt-2">Palun sisestage kehtiv seadistuskood.</p>
          )}

          <button
            type="submit"
            className="w-full bg-brand text-gray-900 py-2 px-4 rounded-md hover:bg-brand/80 transition-colors duration-200 disabled:opacity-50"
            disabled={!isValidDeviceCode}
          >
            Jätka
          </button>
        </form>
        <p className="text-gray-400 text-sm flex items-center">Teid suunatakse juhtpaneeli.</p>
      </div>

      <p className="text-sm text-gray-400 mb-4 text-white items-center opacity-80">
        Kõrged elektrihinnad näpistavad?{' '}
        <a href="https://volton.ee/hinnad" target="_blank" rel="noreferrer" className="text-brand hover:underline ml-1">
          Soeta nutipistik.
        </a>
      </p>
      <p className="text-sm text-gray-400 text-white flex gap-2 items-center">
      <a href="https://volton.ee/kasutusjuhend" target="_blank" rel="noreferrer" className="text-gray-400 underline hover:text-gray-300 ml-1">
          Kasutusjuhend
        </a>
        <a href="https://volton.ee/kontakt" target="_blank" rel="noreferrer" className="text-gray-400 underline hover:text-gray-300 ml-1">
          Kontakt
        </a>
      </p>
    </div>
  );
};

export default Dashboard;
